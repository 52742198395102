<template>
  <div v-bind="timelineAttrs">
    <slot></slot>
  </div>
</template>
<script>
import dbg from 'debug'

const debug = dbg('lnbig:one-step-group')

export default {
  name: "OneStepGroup",
  data() {
    return {
      prevFocusPos: null
    }
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number
    }
  },
  mounted() {
    this.$nextTick(() => {
      debug("childrens = %o", this.$children)
    })
  },
  methods: {
    checkPosition(pos) {
      if (this.active) {
        let items = this.$children

        items.forEach((val, index) => {
          if (index <= pos)
            val.showContent = true
          else
            val.showContent = false

          debug('pos=%d, index=%d, val=%o', pos, index, val)
          if (index === pos)
            val.active = true
          else
            val.active = false
        })

        debug('items[%d] = %o, typeof %s', pos, items[pos], typeof pos)
        debug('items: %o', items)

        this.$nextTick(() => {
          debug('goTo (%d): %o', pos, items[pos])
          this.$vuetify.goTo(
            items[pos],
            {
              duration: 300,
              offset: 30,
              easing: 'easeInOutCubic',
            }
          )
          debug('after goTo')
        })

        if (this.prevFocusPos !== null && items[this.prevFocusPos].blur)
          items[this.prevFocusPos].blur()

        if (items[pos].focus) {
          items[pos].focus()
          this.prevFocusPos = pos
        }
      }
    }
  },
  computed: {
    timelineAttrs() {
      let obj = {}
/*
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          Object.assign(obj, {dense: true})
      }
*/

      return obj
    }
  },
  watch: {
    value: {
      handler(pos) {
        this.$nextTick(() => {
          this.checkPosition(pos)
        })
      },
      immediate: true
    },
    active: {
      handler() {
        this.$nextTick(() => {
          this.checkPosition(this.value)
        })
      },
      immediate: true
    }
  }
}</script>
